section#resume {
    background-color: whitesmoke;
    min-height: 25rem;
    padding-top: 4rem;
    padding-bottom: 2rem;

    #resume-heading {
        color: rgb(40, 40, 40);
        @include heading;
        font-weight: 400;
        letter-spacing: 6px !important;;
        margin-bottom: 2rem;
    }

    div.vertical-timeline-element-content {
        text-align: left;
        font: 18px/24px "opensans-bold",sans-serif;
        padding: 1.1rem;
        
        .vertical-timeline-element-title {
            font-size: 1.5rem;
        }

        .vertical-timeline-element-subtitle {
            font-size: 1rem;
        }

        p {
            font-size: .85rem;
        }
    }
}